<template>
  <div>
    <b-alert :show="!savedPost.length" variant="success"
      >Everything is posted!
      <router-link to="/mypost">Click here</router-link> to write something
      new</b-alert
    >
    <div v-if="savedPost.length">
      <div v-for="(post, index) in savedPost" :key="post._id">
        <div>
          <b-card
            class="m-4"
            header-bg-variant="dark"
            header-text-variant="white"
            border-variant="success"
            :sub-title="post.category"
          >
            <template #header class=""
              >{{ index + 1 }}. {{ post.title }}</template
            >
            <b-card-text>
              {{ post.content }}
            </b-card-text>
            <template #footer>
              <b-button size="md" variant="primary" @click="editArticle(post)">
                Edit
              </b-button>
              <b-button
                class="ml-4"
                v-if="isDeleting"
                variant="danger"
                disabled
              >
                Deleting...
              </b-button>
              <b-button
                v-else
                class="ml-4"
                size="md"
                variant="danger"
                @click="deleteDraft(post)"
              >
                Delete
              </b-button>
            </template>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from "../router/EventBus";
import { BAlert, BCard, BCardText, BButton } from "bootstrap-vue";

export default {
  components: {
    BAlert,
    BCard,
    BCardText,
    BButton,
  },

  data() {
    return {
      isDeleting: false,
    };
  },

  computed: {
    ...mapGetters(["userDetails"]),

    savedPost() {
      return this.userDetails?.savedPost ?? [];
    },
  },

  methods: {
    ...mapActions(["deletesavedArticle", "signOut"]),
    ...mapMutations(["setCurrentArticle"]),

    redirectToCreate() {
      this.$router.push("/mypost");
    },

    editArticle(post) {
      let article = { ...post, isSaved: true };
      this.setCurrentArticle(article);
      this.$router.push("/mypost");
    },

    async deleteDraft(post) {
      try {
        this.isDeleting = true;
        await this.deletesavedArticle({
          id: this.userDetails.id,
          postId: post._id,
        });

        this.$bvToast.toast("Your draft has been deleted successfully!", {
          title: "Deleted successfully!",
          autoHideDelay: 2000,
          variant: "warning",
          solid: true,
        });
      } catch (e) {
        if (e.response.status === 401) {
          this.signOut();
        }
        EventBus.$emit("showCommonModal", {
          title: "Error",
          content: "Error Occurred. Please try again!",
          variant: "danger",
        });
      } finally {
        this.isDeleting = false;
      }
    },
  },
};
</script>
